<template>
    <div class="main-filter">
        <!-- 搜索框 -->
        <div class="main-filter-search">
            <search-component>
            </search-component>
        </div>
        <!-- 筛选条件 -->
        <div class="main-filter-group">
            <filter-list
                filter-title="相机分类："
                :filter-array="testArray1">
            </filter-list>
            <filter-list
                filter-title="摄影器材："
                :filter-array="testArray2">
            </filter-list>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                testArray1: [
                    {
                        value: 1,
                        label: "单电/微单"
                    },
                    {
                        value: 2,
                        label: "单反相机"
                    },
                    {
                        value: 3,
                        label: "数码相机"
                    },
                    {
                        value: 4,
                        label: "拍立得"
                    },
                    {
                        value: 5,
                        label: "旁轴相机"
                    },
                    {
                        value: 6,
                        label: "摄像机"
                    },
                    {
                        value: 7,
                        label: "LOMO相机"
                    },
                    {
                        value: 8,
                        label: "特殊相机"
                    },
                    {
                        value: 9,
                        label: "135胶片单反"
                    }
                ],
                testArray2: [
                    {
                        value: 1,
                        label: "摄影附件"
                    },
                    {
                        value: 2,
                        label: "闪光配件"
                    },
                    {
                        value: 3,
                        label: "摄影灯类"
                    },
                    {
                        value: 4,
                        label: "摄影背景"
                    },
                    {
                        value: 5,
                        label: "三脚架类"
                    },
                    {
                        value: 6,
                        label: "摄影棚套装"
                    },
                    {
                        value: 7,
                        label: "补光灯区"
                    }
                ]
            }
        },
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            filterList: () => import("@/components/equipment/filterList")
        }
    }
</script>

<style scoped>
.main-filter-search{
    width: 60%;
    margin: 0 auto;
}
.main-filter-group{
    margin-top: 50px;
}
.main-filter-group > div + div{
    margin-top: 10px;
}
</style>